

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class InsectPest extends Vue {
  private types = ['未损坏', '已损坏']
  private dataRange = []
  private dataRange1 = []
  private searchInfo = {
    userName: '',
    recordTimeEnd: '',
    recordTimeStart: '',
    backTimeStart: '',
    backTimeEnd: '',
    backState: '',
    machineryId: this.$route.query.id
  }

  private loading = false
  private tableData = []
  private total = 0
  private page = 1
  private size = 10

  created () {
    this.loadData()
  }

  activated () {
    this.onSearch()
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  loadData () {
    if (this.dataRange) {
      this.searchInfo.recordTimeStart = this.dataRange[0]
      this.searchInfo.recordTimeEnd = this.dataRange[1]
    } else {
      this.searchInfo.recordTimeStart = ''
      this.searchInfo.recordTimeEnd = ''
    }
    if (this.dataRange1) {
      this.searchInfo.backTimeStart = this.dataRange1[0]
      this.searchInfo.backTimeEnd = this.dataRange1[1]
    } else {
      this.searchInfo.backTimeStart = ''
      this.searchInfo.backTimeEnd = ''
    }
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryRecordByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onDetail (recordId: any) {
    this.$router.push({
      path: '/machineryManage/return',
      query: {
        recordId
      }
    })
  }
}
